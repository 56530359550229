import React from 'react'

const TwitterIcon = (props) => (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="18" cy="18" r="18" fill="#6B8A6E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M25.0093 15.1588C25.0149 15.2963 25.0182 15.4346 25.0182 15.5737C25.0182 19.8049 21.7979 24.683 15.9089 24.683C14.1006 24.683 12.4177 24.1533 11 23.2451C11.2507 23.2742 11.5054 23.2896 11.7642 23.2896C13.2644 23.2896 14.6449 22.7777 15.7407 21.9188C14.3392 21.893 13.1568 20.967 12.75 19.6949C12.9449 19.7329 13.1455 19.7531 13.3517 19.7531C13.6437 19.7531 13.9267 19.7135 14.1952 19.6407C12.7306 19.3463 11.6276 18.0524 11.6276 16.5013C11.6276 16.4875 11.6276 16.4738 11.6276 16.4608C12.0594 16.7002 12.5527 16.8442 13.0776 16.8612C12.2187 16.287 11.6534 15.3068 11.6534 14.1965C11.6534 13.6093 11.8111 13.0594 12.0869 12.5863C13.6663 14.5232 16.0253 15.7985 18.686 15.9319C18.6318 15.6974 18.6035 15.4532 18.6035 15.2017C18.6035 13.4338 20.0365 12 21.8052 12C22.7263 12 23.5585 12.389 24.1423 13.0117C24.8718 12.8677 25.5568 12.6017 26.1754 12.2345C25.9369 12.9818 25.429 13.6093 24.7675 14.0056C25.4153 13.9288 26.0323 13.7565 26.6065 13.5018C26.1771 14.1439 25.6344 14.7076 25.0093 15.1588Z" fill="white"/>
    </svg>

)

export default TwitterIcon;