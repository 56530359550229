import React from "react"
import { graphql } from "gatsby"
// import Layout from "../components/layout"
// import SEO from "../components/seo"

import { Helmet } from "react-helmet";
import { StaticImage, getImage } from "gatsby-plugin-image";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "gatsby";
import FacebookIcon from '../components/icons/Facebook'
import LinkedinIcon from '../components/icons/Linkedin'
import MailIcon from '../components/icons/Mail'
import TwitterIcon from '../components/icons/Twitter'
import BlogAuthorCard from '../components/BlogAuthorCard'
import { NewsblockContainer, Newsblock } from '../components/Newsblock';
import { CarouselComponent, CarouselComponentSlide } from '../components/carousel';


import "../../scss/main.scss";
import "../../scss/_blog.scss";

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
  }) {
    const { ghostPost: {html, primary_author} } = data

    return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				<link
					rel="shortcut icon"
					type="image/svg"
					href="./assets/marketing-site/images/favicon.svg"
				/>
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Edyn Care | Blog Post</title>
			</Helmet>
			<div className="container">
				<Header />
				<main>
					<div className="blog-post-landing">
						<StaticImage
							className="blog-post-left"
							src="../../static/assets/marketing-site/images/cta-pattern-02.svg"
							objectFit='contain'
							placeholder= "blurred"
							loading="eager"
						/>
						<div className="landing-content">
							<h1>Stories and news from the Edyn team and customers</h1>
							<p>09 Oct 2020</p>
						</div>
						<StaticImage
							className="blog-post-right"
							src="../../static/assets/marketing-site/images/cta-pattern-01.svg"
							objectFit='contain'
							placeholder= "blurred"
							loading="eager"
						/>
					</div>

					<section className="blog-post-context-section">
						<div className="blog-post-entry">
							<div className="blog-post-entry-img">
								<StaticImage
									src="../../static/assets/marketing-site/images/blog-post.jpg"
									alt="Blog post image"
								/>
							</div>
							<div className="blog-post-entry-content">
								<h3 className="secondary">
									Deciding as a family whether live-in care is the right option
									for a loved one is not always easy. You may not have consensus
									on what the best way forward is, or not know which option your
									loved one would prefer. After all, care and support is not
									something we arrange every day!
								</h3>
							</div>
						</div>

						<div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }}/>

						<div className="blog-post-social">
							<Link to="https://www.facebook.com/EdynCare/">
								<FacebookIcon className="social-icon"/>
							</Link>
							<Link to="https://twitter.com/EdynCare">
								<TwitterIcon className="social-icon"/>
							</Link>
							<Link to="https://www.linkedin.com/company/edyn-care/">
								<LinkedinIcon className="social-icon" />
							</Link>
							<Link to="mailto:hello@edyn.care">
								<MailIcon className="social-icon"/>
							</Link>
						</div>

						<BlogAuthorCard
							authorTitle={primary_author.bio}
							authorName={primary_author.name}
							authorProfileImage={getImage(primary_author.profile_image_sharp)}
						/>
					</section>
					<hr className="blog-post-line" />
					
					<NewsblockContainer title="Related posts" className="newsblock-desktop newsblock-latest">
						<Newsblock
							color="yellow"
							date="'{DD MM}'"
							title="What are the qualities of a good carer"
							description="I represent Edyn Care by making a remarkable difference
									in the journey of caring and supporting for our lovely
									clients."
							link="#">
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-5.svg" />
						</Newsblock>
						<Newsblock
							color="yellow"
							date="'{DD MM}'"
							title="How do you know when you need care?"
							description="I represent Edyn Care by making a remarkable difference
									in the journey of caring and supporting for our lovely
									clients."
							link="#">
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-4.svg" />
						</Newsblock>
						<Newsblock
							color="yellow"
							date="'{DD MM}'"
							title="What are the options for elderly care?"
							description="I represent Edyn Care by making a remarkable difference
									in the journey of caring and supporting for our lovely
									clients."
							link="#">
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-3.svg" />
						</Newsblock>
					</NewsblockContainer>


					<NewsblockContainer title="Related posts" className="newsblock-mobile">
					<CarouselComponent>
						<CarouselComponentSlide>
							<Newsblock
								color="yellow"
								date="'{DD MM}'"
								title="What are the qualities of a good carer"
								description="I represent Edyn Care by making a remarkable difference
										in the journey of caring and supporting for our lovely
										clients."
								link="#">
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-5.svg" />
							</Newsblock>
						</CarouselComponentSlide>
						<CarouselComponentSlide>
							<Newsblock
								color="yellow"
								date="'{DD MM}'"
								title="How do you know when you need care?"
								description="I represent Edyn Care by making a remarkable difference
										in the journey of caring and supporting for our lovely
										clients."
								link="#">
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-4.svg" />
							</Newsblock>
						</CarouselComponentSlide>
						<CarouselComponentSlide>
						<Newsblock
							color="yellow"
							date="'{DD MM}'"
							title="What are the options for elderly care?"
							description="I represent Edyn Care by making a remarkable difference
									in the journey of caring and supporting for our lovely
									clients."
							link="#">
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-3.svg" />
						</Newsblock>
						</CarouselComponentSlide>
						</CarouselComponent>
					</NewsblockContainer>
				</main>
			</div>
			<Footer />
		</>
	);
}

export const pageQuery = graphql`
  	query($slug: String!) {
		ghostPost(slug: { eq: $slug }) {
			html
			primary_author {
				bio
				name
				profile_image_sharp {
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		}
  	}
`